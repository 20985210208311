export const environment = {
  name: 'MobileTracking APP',
  blankImage: 'http://localhost:4200/assets/imgs/spin.svg',
  apiUrl: 'https://mobile.catchalot.es/api',
  maxPagesTables: 5,
  orderStatus: [
    { value: 'AQUI', label: 'AQUI' },
    { value: 'PAGADO', label: 'PAGADO' },
    { value: 'ROTURA_STOCK', label: 'ROTURA_STOCK' },
    { value: 'PTE_PAGO', label: 'PTE_PAGO' },
    { value: 'ERROR', label: 'ERROR' },
    { value: 'CANCELADO', label: 'CANCELADO' },
  ]
};